<template>
  <div id="about" class="wrapper">
    <div class="static-container">
      <h1 class="title">about()</h1>

      <div class="std">
        <div class="first-fold">
          <ul class="about-contact">
            <li>
              <a href="https://www.linkedin.com/in/bram-goudsmid-💻-ab2401225/" target="_blank" rel="noopener" title="LinkedIn">
                <svg
                    class="ico"
                    viewBox="0 0 16 16"
                    role="img"
                    aria-labelledby="LinkedinIcoTitle"
                >
                  <title id="LinkedinIcoTitle">LinkedIn logo</title>
                  <path
                      stroke="none"
                      d="M14.8 0H1.2C.5 0 0 .5 0 1.2v13.7c0 .6.5 1.1 1.2 1.1h13.6c.7 0 1.2-.5 1.2-1.2V1.2c0-.7-.5-1.2-1.2-1.2zM4.7 13.6H2.4V6h2.4v7.6zM3.6 5c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4V9.9c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8H6.2V6h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2z"
                  />
                </svg>
              </a>
            </li>
            <li>
              <a href="https://github.com/Broodje-Bram" target="_blank" rel="noopener" title="GitHub">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="ico"
                    viewBox="0 0 16 16"
                    role="img"
                    aria-labelledby="GithubIcoTitle"
                >
                  <title id="GithubIcoTitle">GitHub logo</title>
                  <path
                      stroke="none"
                      fill-rule="evenodd"
                      d="M8 0C3.6 0 0 3.6 0 8c0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4v-1.4c-2.2.5-2.7-1.1-2.7-1.1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.2 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6 0 1.3-.1 2-.1s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.3.6.8.6 1.5v2.2c0 .2.1.5.6.4C13.7 14.5 16 11.5 16 8c0-4.4-3.6-8-8-8z"
                      clip-rule="evenodd"
                  />
                </svg>
              </a>
            </li>
            <li class="-comment">
              Download my
              <a
                  href="/BramGoudsmidCV.pdf"
                  target="_blank"
                  rel="noopener"
                  title="Download Resume"
                  class="bt"
              >
                resume
                <svg xmlns="http://www.w3.org/2000/svg" class="ico" viewBox="0 0 16 16">
                  <path
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M8 11.4l3.3-2.9m-6.6 0L8 11.4V.5M.5 10.8v4.7h15v-4.7"
                  />
                </svg>
              </a>
            </li>
          </ul>

          <p class="-pink">
            I am a Software developer, designer and Sandwich.<br>
            From The Netherlands.
          </p>
        </div>

        <div class="about-grid">
          <h2>Main skills</h2>
          <div class="columns fluent" >
            <ul>
              <li>
                Frontend development,
                <br> Backend Development,
                <br >UX/UI design
              </li>
              <li>
                JavaScript, CSS,
                <br> HTML, PHP, MySQL
                <br>Vue, GSAP, React
              </li>
              <li>
                Photoshop
              </li>
              <li>
                Strategic thinking,
                <br>WordPress
              </li>
            </ul>
          </div>

          <h2>Tools<span>/years</span></h2>
          <div class="columns tools">
            <ul>
              <li>JavaScript/+4</li>
              <li>CSS/+4</li>
              <li>HTML/+4</li>
              <li>PHP/+2</li>
              <li>GSAP/+1</li>
            </ul>
            <ul>
              <li>Vue.js/~1</li>
              <li>React/~1</li>
              <li>Node.js/~1</li>
            </ul>
            <ul>
              <li>MySQL/+2</li>
              <li>Adobe Photoshop/+6</li>
            </ul>
            <ul>
              <li>WordPress/+2</li>
              <li>Strategic Thinking/+∞</li>
            </ul>
          </div>

          <h2>Experience</h2>
          <div class="columns experience">
            <ul>
              <li>
                <b class="-pink">Intern</b>
                <br /><a href="https://www.raion-design.com/" class="-gray">@Raion Design</a>
                <br />2021 - 2022
              </li>

              <li>
                <b class="-pink">Developer</b>
                <br /><a href="https://www.allianz-partners.com" class="-gray">@Allianz Partners</a>
                <br />2023 - Present
              </li>
            </ul>
          </div>

          <h2>Languages</h2>
          <div class="columns languages">
            <ul>
              <li>
                <span class="-comment">// fluent</span>
                <br />
                <i class="-pink">NL</i> Netherlands,
                <br />
                <i class="-pink">EN</i> English
              </li>
              <li>
                <span class="-comment">// basic</span>
                <br />
                <i class="-pink">DE</i> Deutsch
              </li>
            </ul>
          </div>

          <h2>Also busy with</h2>
          <div class="columns busy">
            <ul>
              <li>School</li>
              <li>Video games</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// styles
import "@/styles/about.css";
// GSAP + ScrollMagic
import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax, Power3, Power0 } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export default {
  name: "about",
  data() {
    return {
      intro: new TimelineMax(),
      scroller: new ScrollMagic.Controller()
    };
  },
  methods: {},
  mounted() {
    /**
     * @desc
     * Intro scene
     */
    this.intro
        .addLabel("enter", 1)
        .from(
            ".title",
            2,
            {
              autoAlpha: 0,
              rotationX: 90,
              transformOrigin: "50% 50% -100px",
              ease: Power3.easeOut
            },
            "enter"
        )
        .from(
            ".std",
            2,
            {
              autoAlpha: 0,
              x: -32,
              ease: Power3.easeOut
            },
            "enter+=1.5"
        );

    /**
     * @desc
     * Setup Time lines and Scenes
     */
    let duration = window.innerHeight;

    /**
     * @desc
     * header background scene
     */
    let tlHeader = new TimelineMax();

    tlHeader.to(".header-bg", 4, {
      autoAlpha: 1,
      ease: Power0.easeNone
    });

    new ScrollMagic.Scene({
      triggerElement: "#about",
      offset: duration / 4,
      duration: duration
    })
        .setTween(tlHeader)
        .addTo(this.scroller)
        .reverse(true);
  },
  beforeDestroy() {
    this.scroller.destroy();
  }
};
</script>

